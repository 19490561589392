import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { FirebaseContext } from "../components/Firebase";
import Header from "../components/Header";
import ThickLoadingWall from "../components/ThickLoadingWall";
import styled from "styled-components";
import errorDictionary from "../helpers/errorDictionary";
import { PasswordRounded } from "@mui/icons-material";

const ErrorMessageText = styled.div`
  color: red;
  text-align: center;
`;

const SignBackInContainer = styled.div`
  padding-bottom: 100px;
  margin-bottom: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ecebff;
  font-size: 30px;
`;

function UserManagement() {
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [internalLoading, setInternalLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [passwordForm, setPasswordForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [actionCodeState, setActionCodeState] = useState("");
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  function handleInputChange(e) {
    e.persist();
    setPasswordForm((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
    setErrorMessage("");
    //console.log(passwordForm);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setInternalLoading(true);
    //console.log("handle submit clicked");
    if (passwordForm.password !== passwordForm.confirmPassword) {
      setErrorMessage("Passwords do not match");
      setInternalLoading(false);
      return;
    }
    const newPassword = passwordForm.password;
    firebase.auth
      .confirmPasswordReset(actionCodeState, newPassword)
      .then((res) => {
        setPasswordChangeSuccess(true);
        setInternalLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setErrorMessage(errorDictionary[error.code]);
        setInternalLoading(false);
      });
  }

  function handleResetPassword(auth, actionCode, continueUrl, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    setTitle("Password reset");
    // Verify the password reset code is valid.
    auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        setEmail(email);
        setInternalLoading(false);
        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.

        // Save the new password.
      })
      .catch((error) => {
        setInternalLoading(false);
        //console.log(error);
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
  }

  function handleVerifyEmail(auth, actionCode, continueUrl, lang) {
    user &&
      user.emailVerified &&
      (window.location.href = "/registrationSuccess");
    setTitle("Email verification");
    auth
      .applyActionCode(actionCode)
      .then((resp) => {
        window.location.href = "/registrationSuccess";
        setMessage(
          "Your email has been verified, you will be automatically directed to a new page, but please click the link if you're still here after 5 seconds"
        );
        setInternalLoading(false);
      })
      .catch((error) => {
        setMessage(
          `We had a problem: this link has either expired or been used. Please get a new link.`
        );
        setInternalLoading(false);
      });
  }

  function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  useEffect(() => {
    if (!loading) {
      //console.log("0");
      var mode = getParameterByName("mode");
      var actionCode = getParameterByName("oobCode");
      setActionCodeState(actionCode);
      var continueUrl = getParameterByName("continueUrl");
      var lang = getParameterByName("lang") || "en";

      switch (mode) {
        case "resetPassword":
          // Display reset password handler and UI.
          handleResetPassword(firebase.auth, actionCode, continueUrl, lang);
          break;
        case "verifyEmail":
          handleVerifyEmail(firebase.auth, actionCode, continueUrl, lang);
          break;
        default:
          window.location.href = "/";
      }
    }
  }, [loading]);

  return (
    <div>
      {internalLoading && <ThickLoadingWall />}
      {title == "Email verification" && (
        <>
          <Header title={title} subheading={message} />
          <SignBackInContainer>
            {message ==
            "Your email has been verified, you will be automatically directed to a new page, but please click the link if you're still here after 5 seconds"
              ? "😃"
              : "🤷"}
          </SignBackInContainer>
        </>
      )}
      {title == "Password reset" && (
        <div class="page-header log-in wf-section">
          <h1 class="h1-title">{title}</h1>
          <p class="subheading-text">{message}</p>
          <div class="log-in-card">
            <div class="form-block w-form">
              <div class="text-block-5">🔐</div>
              <form name="passwordForm" class="form" onSubmit={handleSubmit}>
                <input
                  value={passwordForm.password}
                  type="password"
                  class="text-field w-input"
                  name="password"
                  placeholder="password"
                  onChange={handleInputChange}
                  id="Password"
                  required
                />
                <input
                  value={passwordForm.confirmPassword}
                  type="password"
                  class="text-field w-input"
                  name="confirmPassword"
                  placeholder="confirm password"
                  id="ConfirmPassword"
                  onChange={handleInputChange}
                  required
                />
                {!!errorMessage && (
                  <div>
                    <ErrorMessageText>{errorMessage}</ErrorMessageText>
                  </div>
                )}
                <input
                  type="submit"
                  value={
                    passwordChangeSuccess ? "Password set" : "Set password"
                  }
                  class={
                    passwordChangeSuccess
                      ? "button-success top-displacement w-button"
                      : "button top-displacement w-button"
                  }
                  disabled={passwordChangeSuccess ? true : false}
                />
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserManagement;
